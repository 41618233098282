.head{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-left: 30px;
    margin-right: 30px;
    height: 37.5rem;
}
.heading{
    font-size: 4.375rem;
    font-weight: 400;
    text-align: center;
    color: #B6EADA;
}
.sub-heading{
    margin: 0%;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #5B8FB9;
}