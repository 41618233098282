html {
  overflow-x: hidden;
}


body {
  background-color: #03001C;
  font-family: 'Space Mono';
  color: #B6EADA;
  overflow-x: hidden;

}
